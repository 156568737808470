
export type categories = 'people' | 'nature' | 'design';

interface Images {
    id: number;
    category: categories;
}

export const images: Images[] = [
    { id: 1, category: 'people' },
    { id: 2, category: 'nature' },
    { id: 3, category: 'people' },
    { id: 4, category: 'nature' },
    { id: 5, category: 'nature' },
    { id: 6, category: 'nature' },
    { id: 7, category: 'people' },
    { id: 8, category: 'nature' },
    { id: 9, category: 'nature' },
    { id: 10, category: 'people' },
    { id: 11, category: 'people' },
    { id: 12, category: 'nature' },
    { id: 13, category: 'nature' },
    { id: 14, category: 'nature' },
    { id: 15, category: 'people' },
    { id: 16, category: 'nature' },
    { id: 17, category: 'people' },
    { id: 18, category: 'people' },
    { id: 19, category: 'people' },
    { id: 20, category: 'people' },
    { id: 21, category: 'people' },
    { id: 22, category: 'people' },
    { id: 23, category: 'nature' },
    { id: 24, category: 'people' },
    { id: 25, category: 'nature' },
    { id: 26, category: 'nature' },
    { id: 27, category: 'design' },
    { id: 28, category: 'design' },
    { id: 29, category: 'nature' },
    { id: 30, category: 'design' },
    { id: 31, category: 'design' },
    { id: 32, category: 'design' },
    { id: 33, category: 'design' },
    { id: 34, category: 'design' },
    { id: 35, category: 'design' },
    { id: 36, category: 'design' },
    { id: 37, category: 'design' },
    { id: 38, category: 'design' },
    { id: 39, category: 'nature' },
    { id: 40, category: 'nature' },
    { id: 41, category: 'nature' },
    { id: 42, category: 'nature' },
    { id: 43, category: 'nature' },
    { id: 44, category: 'people' },
    { id: 45, category: 'people' },
    { id: 46, category: 'people' },
    { id: 47, category: 'people' },
    { id: 48, category: 'nature' },
    { id: 49, category: 'nature' },
    { id: 50, category: 'people' },
    { id: 51, category: 'design' },
    { id: 52, category: 'people' },
    { id: 53, category: 'people' },
    { id: 54, category: 'people' },
    { id: 55, category: 'people' },
    { id: 56, category: 'people' }
]