import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'

@customElement('hersing-image')
export class HersingImage extends LitElement{

    @property({  type: String, attribute: true, reflect: true})
    src: string | undefined;

    @property({  type: String, attribute: true, reflect: true})
    alt: string | undefined;

    override render(){
        const {src, alt} = this;
        return html`		
            <img  src="./asserts/images/${src}" alt=${ifDefined(alt)}/>
        `
    }

    static override get styles(){
        return css`
            :host {
                display: inline-block;
            }

            img {
                height: 100%;
                width: 100%;
            }
        `
    }
}