import { html, css } from 'lit';
import { customElement } from 'lit/decorators.js'

import { link } from '../../utils';
import { Section } from './section.base';

@customElement('hersing-section-about-me')
export class HersingSectionAboutMe extends Section {

    links = [
        { title: 'Gentofte Kommune', href: 'http://www.gentofte.dk/' },
        { title: 'Kultur Klub Gentofte', href: 'https://www.gentofte.dk/da/Borger/Kultur-og-fritid/Kultur/Kulturklub-Gentofte' },
        { title: 'Den Blå Planet', href: 'http://www.denblaaplanet.dk/' },
        { title: 'Instituttet for Blinde og Svagsynede', href: 'http://ibos.dk' },
        { title: 'Dansk Blindesamfund', href: 'http://dkblind.dk' },
        { title: 'AirPlay Street Gallery', href: '' }
    ]

    override render() {
        const {links} = this;
        return html`
        <div>
            <hersing-image src="me2.webp"></hersing-image>
            <h3>Daniel Green Hersing</h3>
            <h3>a Creative Mind</h3>
            <p>I have a masters degree in Communication Studies with my thesis in Performance Design and Informatics.</p>
            <p>
                My education makes me the best candidate for a job in cultural firms where concepts and events are made.<br/>
                My education has brought me knowledge in the areas of aesthetics, usability and great design.<br/>
                I have learned what it will say to be a team leader, and what will have to be done to complete a task according to schedule.
            </p>
            <p>
                Beside my educational values, i have played the guitar in over a decade, where everything is self-taught, which proves the point that the goal will be reached.<br/>
                I also enjoy a lot of creative photographing, photoshop-play and web designing, which shows that my passion is within the field of innovation and creativity.
            </p>
				<p>
				In work related context: I hate to be late, so i will always be there before or on time, and one of my personal mottos is 
				<em>&quot;Never let others down&quot;</em>
				, which mean i will do my best to deliver a perfect piece of work.
				<br/>
				I am service minded and accommodating, and easy to be around. I will always be there with a smile and a helping hand.
				<br/>
				But that doesn&#39;t mean i&#39;m afraid to say what needs to be said.
				</p>
				<h4>
                    Links to Former Collaborators:
                </h4>
                ${links.map(link)}
        </div>
        `;
    }

    static override get styles() {
        return css`
        div{
            text-align: center;
            font-size: 1.5em;
        }

        img{
            border-radius: 50%;
            height: 260px;
            width: 260px;
        }

        a{
            color: inherit;
            display: block;
            text-decoration: none;
        }
        `;
    }
}