import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js'

import { slideOut } from '../animations';

@customElement('hersing-loader')
export class HersingLoader extends LitElement{

    override connectedCallback(){
        super.connectedCallback();

        setTimeout(() => {
            slideOut(this.warpper, 500)
        }, 1000);
    }

    get warpper() {
        return this.shadowRoot?.getElementById('warpper')
    }

    override render(){
        return html`		
        <div class="wrapper" id="warpper">
            <hersing-image src="loading-logo.gif" alt="Loading..." class="hent"></hersing-image>
        </div>`
    }

    static override get styles(){
        return css`
            .wrapper{
                height: 100vh;
                width: 100vw;
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: white;
                z-index: 99999;
            }

            hersing-image {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -25px;
                margin-left: -69px;
            }
        `;
    }
    
}