import { html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js'

import { categories, images } from './images';

import '@power-elements/lazy-image';
import { arrow_left, arrow_right } from '../../svgs';
import { HersingElement } from '../../hersingElement';

type sort_categories = categories | 'all';

@customElement('hersing-section-portfolio')
export class HersingSectionPortfolio extends HersingElement {

    @state()
    current_category: sort_categories = 'all';

    @state()
    current_image: number | undefined = undefined;

    override render() {
        const {sorter, overlay} = this;
        return html`
            ${sorter}
            <div>
                ${this.getImages()}
            </div>
            ${overlay}
        `;
    }

    *getImages() {
        const {current_category} = this;
        for (const { id, category } of images) {

            if(current_category !== 'all' && current_category !== category)
                continue;

            yield html`
                <a class="portfolio-click hover" @click=${() => this.show_image(id)}>
                    <div class="img">
                        <hersing-image src="portfolio/small/${id}.webp"></hersing-image>
                    </div>
                </a>
            `
        }
    }

    show_image(image_id: number | undefined){
        const {current_image} = this;

        if(current_image == null && image_id == null)
            return;

        if(current_image == null){
            window.addEventListener('keydown', this.keyPressed)
            this.setScrollToBody(false);
        } else if(image_id == undefined){
            window.removeEventListener('keydown', this.keyPressed)
            this.setScrollToBody(true);
        }

        this.current_image = image_id;
    }

    keyPressed = (event: KeyboardEvent) => {
        const { key } = event;
        if( key === 'ArrowLeft'){
            this.goToImage('prev')
        } else if( key === 'ArrowRight'){
            this.goToImage('next')
        }
    }

    get overlay(){
        const {current_image} = this; 

        if(current_image == null)
            return html``;

        return html`
            <hersing-overlay @hersing_toggle_overlay=${() => this.show_image(undefined)} show hideBorder>
                <div class="arrow left" @click=${ () => this.goToImage('prev') }>
                    ${arrow_left}
                </div>
                <hersing-image class="image-in-overlay" src="portfolio/big/${current_image}_b.webp"></hersing-image>
                <div class="arrow right" @click=${ () => this.goToImage('next') }>
                    ${arrow_right}
                </div>
            </hersing-overlay>
        `;
    }

    goToImage(direction: 'prev' | 'next'){
        const {current_image} = this; 
        if(current_image == null)
            return;

        const ids = images.map(i => i.id);
        const min = Math.min(...ids);
        const max = Math.max(...ids);
        const add = direction === 'prev' ? -1 : 1;

        let newId = current_image + add;

        if(newId < min){
            newId = max;
        } else if(newId > max){
            newId = min;
        }

        this.current_image = newId;
    }

    sort_categories: { title: string, category: sort_categories }[] = [
        { title: 'All', category: 'all'},
        { title: 'People', category: 'people'},
        { title: 'Nature', category: 'nature'},
        { title: 'Design', category: 'design'}
    ]

    get sorter(){
        const { sort_categories, current_category } = this;
        return html`
            <ul id="sort_portfolio_button">
                ${
                    sort_categories.map(cat => html`<li class="${current_category === cat.category ? 'selected' : ''}" @click=${() => this.setSortCategory(cat.category)}>${cat.title}</li>`)
                }
            </ul>
        `;
    }

    setSortCategory(category: sort_categories){
        this.current_category = category;
    }

    static override get styles() {
        return css`
            div{
                max-width: 1050px;
                margin: 0 auto;
            }
            
            a{
                filter: blur(1px);
                height: 200px;
                width: 200px;
                display: inline-block;
            }

            .img{
                height: 200px;
                width: 200px;
                overflow: hidden;
            }

            a:hover{
                filter: blur(0);
            }

            a hersing-image{
                transform: scale(1.3);
                transition: .3s ease-in-out;
            }
            a:hover hersing-image{
                transform: scale(1);
            }

            hersing-image {
                cursor: pointer;
            }

            .arrow{
                position: fixed;
                top: 0;
                bottom: 0;
                display: flex;
            }

            .arrow.left{
                left: 4em;
            }

            .arrow.right{
                right: 4em;
            }

            .arrow svg{
                height: 7em;
                display: block;
                margin: auto;
                cursor: pointer;
            }

            .image-in-overlay{
                height: auto;
                max-height: 100%;
                max-width: 100%;
                box-shadow: var(--font-color) 0px 0px 35px 2px;
            }

            lazy-image{
                height: 100%;
                width: 100%;
                cursor: pointer;
                display: inline-block;
            }

            li{
                display: inline;
                text-decoration: none;
                margin: 0;
                padding-right: 30px;
                padding-left: 30px;
                padding-top: 0;
                padding-bottom: 0;
                text-decoration: none;
                color: var(--font-color);
                font-size: 1.5em;
                cursor: pointer;
            }

            li.selected{
                text-decoration: underline;
            }
        `;
    }
}