/* SLIDE UP */
export function slideUp(target: HTMLElement | null | undefined, duration: number = 200) {
    if (target == null)
      return;

    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = `${0}`;
    target.style.paddingTop = `${0}`;
    target.style.paddingBottom = `${0}`;
    target.style.marginTop = `${0}`;
    target.style.marginBottom = `${0}`;
    window.setTimeout(() => {
      target.style.display = 'none';
      target.style.removeProperty('height');
      target.style.removeProperty('padding-top');
      target.style.removeProperty('padding-bottom');
      target.style.removeProperty('margin-top');
      target.style.removeProperty('margin-bottom');
      target.style.removeProperty('overflow');
      target.style.removeProperty('transition-duration');
      target.style.removeProperty('transition-property');
      //alert("!");
    }, duration);
}

/* SLIDE DOWN */
export function slideDown(target: HTMLElement | null | undefined, duration: number = 200) {

    if (target == null)
      return;

    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;
    if (display === 'none') display = 'block';
    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = `${0}`;
    target.style.paddingTop = `${0}`;
    target.style.paddingBottom = `${0}`;
    target.style.marginTop = `${0}`;
    target.style.marginBottom = `${0}`;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout(() => {
      target.style.removeProperty('height');
      target.style.removeProperty('overflow');
      target.style.removeProperty('transition-duration');
      target.style.removeProperty('transition-property');
    }, duration);
}


export function slideOut(target: HTMLElement | null | undefined, duration: number = 200) {
  if (target == null)
    return;

  target.style.transitionProperty = 'width, margin, padding';
  target.style.transitionDuration = duration + 'ms';
  target.style.boxSizing = 'border-box';
  target.style.width = target.offsetWidth + 'px';
  target.offsetWidth;
  target.style.overflow = 'hidden';
  target.style.width = `${0}`;
  target.style.paddingLeft = `${0}`;
  target.style.paddingRight = `${0}`;
  target.style.marginLeft = `${0}`;
  target.style.marginRight = `${0}`;
  window.setTimeout(() => {
    target.style.display = 'none';
    target.style.removeProperty('width');
    target.style.removeProperty('padding-left');
    target.style.removeProperty('padding-right');
    target.style.removeProperty('margin-left');
    target.style.removeProperty('margin-right');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
    //alert("!");
  }, duration);
}