import { LitElement } from 'lit';


export class Section extends LitElement {
    get isInViewport() {
        var rect = this.getBoundingClientRect();
    
        let {y, height} = rect;

        y = y - (window.innerHeight/2);

        return y < 0 && y + height > 0;
    }
}