import { Section } from './components/sections/section.base';
export class HersingElement extends Section{

    get body(){
        return document.querySelector('body');
    }

    setScrollToBody(scroll: boolean){
        const { body } = this;
        
        if(body == null)
            return;
    
        if(scroll){
            body.classList.remove('no-scroll');
        } else{
            body.classList.add('no-scroll');
        }
    }

}