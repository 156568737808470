import { html } from 'lit';

export function range(size:number, startAt:number = 0): ReadonlyArray<number> {
    return [...Array(size).keys()].map(i => i + startAt);
}

export function link(link: {title: string, href: string}){
    const {title, href} = link;

    if(isNullOrWhitespace(href))
        return html`${title}`;
        
    return html`<a title="${title}" href="${href}" target="_blank">${title}</a>`;}

export function isNullOrWhitespace(x: any): boolean {
    return x == null || typeof x === 'string' && /^ *$/.test(x);
}

export function isNotNullOrWhitespace(x: any): boolean {
    return !isNullOrWhitespace(x)
}