import { css, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { scroll_to_next_event } from '../events/scroll-to-next';
import { arrow_down } from '../svgs';
import { Section } from './sections/section.base';

@customElement('hersing-section')
export class HersingSection extends Section {

    @property({ type: Boolean, reflect: true })
    hideName: boolean = false;

    @property({ type: String, reflect: true })
    name!: string;

    @property({ type: Boolean, reflect: true })
    last: boolean = false;

    get href() {
        const { name } = this;
        const newName = name?.replace(' ', '--') ?? '';
        return `--${newName}--`
    }

    get sectionTitle() {
        const { name, hideName, href } = this;
        if (name == null || hideName)
            return html``;

        return html`
            <table id="${href}">
				  <tbody>
                      <tr>
				        <td>
                            <hr>
                        </td>
                        <td class="title_td">
                            ${name}
                        </td>
                        <td>
                            <hr>
                        </td>
				    </tr>
                </tbody>
            </table>
        `;
    }

    get nextSection() {
        const { last } = this;

        if(last)
            return html``;
            
        return html`
        <div @click=${this.scrolToNext} class="next-section-wrapper">
            <div>
                Next Section
            </div>
            <div>
                ${arrow_down}
            </div>
        </div>
        `;
    }

    scrolToNext() {
        this.dispatchEvent(new CustomEvent(scroll_to_next_event, {
            bubbles: true,
            cancelable: true,
            composed: true,
            detail: this.href
        }))
    }

    override render() {
        const { sectionTitle, nextSection } = this;
        return html`
        <div class="section-wrapper">
            ${sectionTitle}
            <slot></slot>
            ${nextSection}
        </div>
        `;
    }

    static override get styles() {
        return css`
            :host {
                display: block;
            }

            p, div{
                text-align: center;
            }

            .next-section-wrapper{
                cursor: pointer;
                font-size: 2em;
                margin-top: 2em;
            }

            svg{
                animation-name: bounce-5;
                animation-timing-function: ease;
                align-self: flex-end;
                animation-duration: 2s;
                animation-iteration-count: infinite;
                height: 1em;
                margin: 0 auto 0 auto;
                transform-origin: bottom;
            }

            @keyframes bounce-5 {
                0%   { transform: scale(1,1)    translateY(0); }
                10%  { transform: scale(1.1,.9) translateY(0); }
                30%  { transform: scale(.9,1.1) translateY(50px); }
                50%  { transform: scale(1,1)    translateY(0); }
                57%  { transform: scale(1,1)    translateY(7px); }
                64%  { transform: scale(1,1)    translateY(0); }
                100% { transform: scale(1,1)    translateY(0); }
            }

            .section-wrapper{
                padding-bottom: 1000px;
            }

            table{
                width: 100%;
                margin-bottom: 3em;
                padding-top: 5em;
            }

            .title_td{
                width: 1px;
                padding: 0 10px;
                white-space: nowrap;
                font-size: 38px;
            }
            
            hr{
                background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.74902), rgba(0, 0, 0, 0));
                background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.74902), rgba(0, 0, 0, 0));
                border: 0;
                height: 1px;
            }
        `;
    }
}