import { html, css, svg } from 'lit';
import { customElement, property } from 'lit/decorators.js'

import { toggle_overlay } from '../events/toggle-overlay';
import { HersingElement } from '../hersingElement';

@customElement('hersing-overlay')
export class HersingOverlay extends HersingElement {

    @property({type: Boolean, reflect: true})    
    show: boolean = false;

    @property({type: Boolean, reflect: true})    
    hideBorder: boolean = false;

    override render() {
        const { show, closeIcon, hideBorder } = this;

        this.setScrollToBody(true);

        if(!show)
            return html``;

        this.setScrollToBody(false);

        return html`
        <div class="wrapper">
            <div class="content ${hideBorder ? 'hide-border' : ''}">
                <slot></slot>
            </div>
            <div class="close" @click=${this.close}>
                ${closeIcon}
            </div>
        </div>
        `;
    }

    close(){
        this.dispatchEvent(new CustomEvent(toggle_overlay, {
            bubbles: true,
            cancelable: true,
            composed: true,
            detail: false
        }))
    }

    get closeIcon(){
        return svg`
            <svg class="svg-icon" style="vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M147.374257 158.246733l10.351981-10.351981 717.269914 717.269914-10.351981 10.351981-717.269914-717.269914Z"  />
                <path d="M147.374139 865.168212l717.269914-717.269914 10.351981 10.351981-717.269914 717.269914-10.351981-10.351981Z"  />
            </svg>
        `;
    }

    static override get styles() {
        return css`
            .wrapper{
                background: rgba(255, 255, 255, 1);
                width: 100vw;
                height: 100vh;
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;
            }

            .content{
                width: calc(100vw - 160px);
                height: calc(100vh - 80px);
                margin: 40px auto;
                border: 1px solid black;
            }

            .content.hide-border{
                border: none;
            }

            .close{
                position: fixed;
                height: 40px;
                width: 40px;
                top: 45px;
                right: 15px;
                cursor: pointer;
            }

            svg{
                width: 100%;
                height: 100%;
            }
        `;
    }
    
}