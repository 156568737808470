import { html, LitElement, css} from 'lit';
import {customElement, state} from 'lit/decorators.js'

import { scroll_to_next_event } from '../events/scroll-to-next';
import { HersingElement } from '../hersingElement';
import { slideDown, slideUp } from '../animations';
import { HersingSection } from './hersing-section';

@customElement('hersing-navigation')
export class HersingNavigation extends HersingElement {

    override connectedCallback() {
        super.connectedCallback();
        window.addEventListener(scroll_to_next_event, this.scrollToNext)
        window.addEventListener('scroll', this.scrolling)
    }
    
    @state()
    show_hidden_menu: boolean = false;

    @state()
    sectionInViewport: string | undefined;

    get sections(): HersingSection[] {
        return Array.from(this.querySelectorAll('hersing-section'));
    }

    get sectionNames() {
        const { sections } = this;

        const names: { name: string, href: string }[] = [];

        sections.forEach(section => {
            names.push({ 
                name: section.name, 
                href: section.href            
            });
        });

        return names;
    }

    get menuItems() {
        const { sectionNames, sectionInViewport } = this;

        return sectionNames.map(section => {
            return html`
                <li class="${sectionInViewport ===section.name ? 'selected': ''}">
                    <a sectionId="${section.href}" @click=${() => this.scrollHere(section.href)}>
                        ${section.name}
                    </a>
                </li>`
        });
    }

    scrollHere(href: string) {
        const { sections } = this;

        if(href === '--Home--'){
            window.scrollTo({top: 0, behavior: 'smooth'})
            return;
        }

        const section = sections.find(s => s.href === href);

        if(section == null)
            return; 

        this.scrollToSection(section);
    }

    scrollToNext = (event: any) => {
        const { sections } = this;

        const ev = event as CustomEvent<string>;
        const index = sections.findIndex(s => s.href == ev.detail) + 1
        const section = sections[index];
        this.scrollToSection(section);
    }

    get hidden_menu() {
        return this.shadowRoot?.getElementById('hidden-menu')
    }

    scrolling = (event: Event) => {
        const { show_hidden_menu, sections } = this;
        const show_hidden_menu_calculated = window.scrollY > 500;

        if (!show_hidden_menu && show_hidden_menu_calculated){
            this.show_hidden_menu = show_hidden_menu_calculated;
            slideDown(this.hidden_menu);
        } else if(show_hidden_menu && !show_hidden_menu_calculated){
            this.show_hidden_menu = show_hidden_menu_calculated;
            slideUp(this.hidden_menu);
        }

        for(const section of sections){
            if(section.isInViewport){
                this.sectionInViewport = section.name;
                break;
            }
        }
    }

    scrollToSection = (section: LitElement) => {
        const option: ScrollIntoViewOptions = { behavior: 'smooth', inline: 'end'  };
        section.scrollIntoView(option);
    }

    get menuList(){
        const { menuItems } = this;

        return html`
            <ul>
                ${menuItems}
            </ul>
        `
    }

    get menu() {
        const { menuList } = this;

        return html`
        <div> 			
            <a href="http://hersing.dk">
                <hersing-image src="logo.png" alt="logo"></hersing-image>
            </a>
			<hr>
            ${menuList}
			<hr>

        </div>
        `;
    }

    get hiddenMenu(){
        const { menuList } = this;

        return html`
        <div id="hidden-menu" class="hidden-menu">
            ${menuList}
        </div>
        `;
    }

    override render() {
        const { menu,hiddenMenu } = this;
        return html`
        ${menu}
        ${hiddenMenu}
        <slot></slot>
        `;
    }

    static override get styles() {
        return css`

            .hidden-menu{
                width: 100vw;
                position: fixed;
                top: 0;
                left: 0;
                display: none;
                z-index: 100;
                background: var(--font-color);
                margin: 0;
            }

            .hidden-menu ul{
                margin: 0;
                padding: 0;
                list-style: none;
                text-align: center;
                padding-top: 0;
                padding-bottom: 0;

                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;
            }

            .hidden-menu ul li{
                display: inline-block;
                text-decoration: none;
                margin: 0px;
                padding: 0px 30px;
                height: 30px;
            }

            .hidden-menu ul li a{
                height: 30px;
                text-decoration: none;
                color: var(--background-color);
                font-size: 20px;
                padding-top: 0;
                padding-bottom: 0;
                cursor: pointer;
                display: block;
            }

            .hidden-menu ul li.selected a {
                color: var(--hersing-color)
            }

            div{
                margin-bottom: 5em;
            }

            hersing-image {
                padding-top: 30px;
                max-width: 180px;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }

            hr{
                width: 60vw;
                background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.74902), rgba(0, 0, 0, 0));
                background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.74902), rgba(0, 0, 0, 0));
                border: 0;
                height: 1px;
            }

            ul{
                padding: 0;
                list-style: none;
                text-align: center;
                padding-top: 0;
                padding-bottom: 0;
            }

            li{
                display: inline;
                text-decoration: none;
                margin: 0;
                padding-right: 1em;
                padding-left: 1em;
                padding-top: 0;
                padding-bottom: 0;
                font-size: 2em;
                cursor: pointer;
            }

            a{
                text-decoration: none;
                color: var(--font-color);
                font-size: 1.6vw;
                padding-top: 0;
                padding-bottom: 0;
            }
        `;
    }
}