import { html, LitElement, css } from 'lit';
import { customElement, property } from 'lit/decorators.js'

@customElement('hersing-iframe-viewer')
export class HersingIFrameViewer extends LitElement {

    @property({type: Boolean, reflect: true})
    show: boolean = false;

    @property({type: String, reflect: true})
    url: string | undefined;

    get showOverlay(){
        const {show, url} = this;
        if(url == null)
            return false;

        return show
    }

    get iframe(){
        const { showOverlay, url } = this;

        if(!showOverlay || url == null)
            return html``;

        return html`<iframe src="${url}"></iframe>`;
    }

    override render() {
        const { showOverlay, iframe } = this;

        return html`
            <hersing-overlay .show=${showOverlay}>
                ${iframe}
            </hersing-overlay>
        `;
    }

    static override get styles() {
        return css`
            iframe {
                width: 100%;
                height: 100%;
            }
        `;
    }
}