import { html, LitElement, css } from 'lit';
import { customElement } from 'lit/decorators.js'


import './components';

@customElement('hersing-app')
export class HersingApp extends LitElement {
    override render() {
        return html`
        <hersing-loader></hersing-loader>
        <hersing-navigation>
            <hersing-section name="Home" hideName>
                <hersing-section-intro></hersing-section-intro>
            </hersing-section>
            <hersing-section name="About me">
                <hersing-section-about-me></hersing-section-about-me>
            </hersing-section>
            <hersing-section name="My skills">
                <hersing-section-my-skills></hersing-section-my-skills>
            </hersing-section>
            <hersing-section name="Portfolio">
                <hersing-section-portfolio></hersing-section-portfolio>
            </hersing-section>
            <hersing-section name="Projects">
                <hersing-section-projects></hersing-section-projects>
            </hersing-section>
            <hersing-section name="Misc.">
                <hersing-section-mics></hersing-section-mics>
            </hersing-section>
            <hersing-section name="Contact" last>
                <hersing-section-contact></hersing-section-contact>
            </hersing-section>
        </hersing-navigation>
        <div>
			&#169; Daniel Green Hersing
		</div>
        `;
    }

    static override get styles(){
        return css`
        div{
            text-align: center;
        }`
    }
}