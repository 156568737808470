import { html, css, LitElement } from 'lit';
import { customElement, query } from 'lit/decorators.js'

@customElement('hersing-roeversprog')
export class HersingRoversprog extends LitElement {

    @query('#translate')
    translateField: HTMLTextAreaElement | undefined;

    @query('#translated')
    translatedField: HTMLTextAreaElement | undefined;

    override render() {
        return html`
            On this site you can translate text into/from what we in danish call "Røversprog". <br>
		    Input the text you want translated:<br>
	<textarea rows="6" cols="50" id="translate" class="roversprog_class"></textarea><br>
	This is the result:<br>
	<textarea rows="6" cols="50" id="translated" class="roversprog_class" readonly> </textarea> <br>
	<button @click=${() => this.convert(false)} id="roversprog_translate">Translate <b><u>TO</u></b> Røversprog</button>
	<button @click=${() => this.convert(true)} id="roversprog_translated">Translate <b><u>FROM</u></b> Røversprog</button>
    `;
    }

    convert = (fromRoversprog: boolean = false) => {
        const { translateField, translatedField, letters } = this;

        if(translateField == null || translatedField == null)
            return;

        let str = translateField.value.toLowerCase();

        for(const letter of letters){
            const from = fromRoversprog ? `${letter}o${letter}` : letter;
            const to = fromRoversprog ? letter : `${letter}o${letter}`;
            str = str.split(from).join(to)
        }
        
        translatedField.innerHTML=str;	
        translatedField.select();
    };

    static override get styles() {
        return css`
        `;
    }

    letters = [
        'b', 
        'c',
        'd',
        'f',
        'g',
        'h',
        'j',
        'k',
        'l',
        'm',
        'n',
        'p',
        'q',
        'r',
        's',
        't',
        'v',
        'w',
        'x',
        'z'
    ]
}