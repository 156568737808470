import { html, css } from 'lit';
import { customElement } from 'lit/decorators.js'

import 'ken-burns-carousel';
import { range } from '../../utils';
import { Section } from './section.base';

@customElement('hersing-section-intro')
export class HersingSectionIntro extends Section {

    get images(){
        return range(6, 1).map(nr => `./asserts/images/slider/slide${nr}.webp`).join(' ');
    }

    get imageSlider() {
        const { images } = this;
        return html`
        <div>
            <ken-burns-carousel 
            fade-duration="1000"
            slide-duration="5000"
            animation-direction="normal"
            images="${images}">
            </ken-burns-carousel>
        </div>
        `;
    }
    override render() {
        const { imageSlider } = this;
        return html`
            <div class="content">
                <p>Welcome to my site.</p>
                <p>Here you will be presented with my Portfolio, info about me, and info on how to reach me.</p>
                <p>Under Misc. you will find my web designs, test codes, music and more.</p>
                <p class="thank-you">Thank you for your interest</p>
                ${imageSlider}
            </div>
            <div class="background"></div>
        `;
    }

    static override get styles() {
        return css` 
            * {
                font-family: inherit;
            }
            div {
                text-align: center;
            }

            p {
                margin: 0;
                font-size: 1.5em;
                text-align: center;
            }
            .thank-you {
                padding-top: 3em;
            }

            svg {
                height: 100%;
                width: 100%;
            }

            .background {
                background-image: url('./asserts/images/lolland.jpeg');
            }

            ken-burns-carousel {
                display: inline-block;
                height: 300px;
                width: 620px;            
            }
        `;
    }
}