
import { html, css, HTMLTemplateResult } from 'lit';
import { customElement, state} from 'lit/decorators.js'

import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import { HersingElement } from '../../hersingElement';
import '../mics';

interface link {
    title: string;
    link?: string;
    template?: HTMLTemplateResult;
}
@customElement('hersing-section-mics')
export class HersingSectionMisc extends HersingElement {

    @state()
    current_link: link | undefined;

    links: link[] = [
        { title: 'Røversprogsgenerator', template: html`<hersing-roeversprog></hersing-roeversprog>` },
        { title: 'Mail prototype - made in <i>Axure</i>', link: 'http://hersing.dk/ahci' },
        { title: "It's snowing", link: 'http://hersing.dk/canvas' },
        { title: 'My CV', link: 'http://hersing.dk/cv' },
        { title: 'Tietgen kollegiet 14.3 Kitchenlist-generator', link: 'http://hersing.dk/madliste' },
        { title: 'Mobileball (gravity) - visit from a mobile phone', link: 'http://hersing.dk/mobileball' },
        { title: 'Music from me and my old band', link: 'http://hersing.dk/music' },
        { title: 'Musicvideo background v.1 me playing', link: 'http://hersing.dk/videotest/' },
        { title: 'Musicvideo background  v.2', link: 'http://hersing.dk/musicvideo/' }
    ];

    show_link(link: link | undefined) {
        this.setScrollToBody(link ? false : true);
        this.current_link = link;
    }

    *getLinks() {
        const { links } = this;
        for (const link of links) {
            yield html`
                <p @click=${() => this.show_link(link)}>
                    ${unsafeHTML(link.title)}
                </p>
            `
        }
    }

    get iFrame() {
        const { current_link } = this;

        if (current_link == null)
            return html``;

        const { link, template} = current_link
        if(link)
             return html`
            <hersing-iframe-viewer 
                url="${link}"  
                @hersing_toggle_overlay=${() => this.show_link(undefined)} 
                show>
            </hersing-iframe-viewer>
        `;

        return html`
            <hersing-overlay @hersing_toggle_overlay=${() => this.show_link(undefined)} show>
                ${template}
            </hersing-overlay>
        `
    }

    override render() {
        const { iFrame } = this;
        return html`
            ${this.getLinks()}
            ${iFrame}
        `;
    }

    static override get styles() {
        return css`
            p{
                font-size: 1.5em;
                cursor: pointer;
            }
        `;

    }
}