import { html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js'

import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { HersingElement } from '../../hersingElement';

interface Project {
    title: string;
    subtitle?: string;
    id: number;
    classStr: string;
    subClass?: string;
}

@customElement('hersing-section-projects')
export class HersingSectionProjects extends HersingElement {

    @state()
    current_id: number | undefined;

    @state()
    current_html: string | undefined;

    @state()
    showIframe: boolean = false;

    projects: Project[] = [
        { title: 'Biodiesel fra mikroalger', id: 1, classStr: 'div3' },
        { title: 'Pizza-Termodynamik', id: 2, classStr: 'div4' },
        { title: 'Pluripotent stamcelleforskning', id: 3, classStr: 'div5' },
        { title: 'Program til Fysikhistorieyndervisningen', id: 4, classStr: 'div6' },
        { title: 'Procesunderstøttelse af Building Information Modelling', id: 5, subtitle: 'Bachelor', classStr: 'div7', subClass: 'div1' },
        { title: 'Mario Kart Grand Prix', id: 6, classStr: 'div8' },
        { title: 'Emotion Boulevard', id: 7, classStr: 'div9' },
        { title: 'Lectio.dk', id: 8, classStr: 'div10' },
        { title: 'Sansekoncert', id: 10, subtitle: 'Thesis', classStr: 'div11', subClass: 'div2' }
    ]

    get abstract() {
        const { current_html, current_id } = this;

        if (current_html == null)
            return html``;

        return html`
           <hersing-overlay show @hersing_toggle_overlay=${this.toggle_overlay}>
            <div class="project-info-wrapper">
                    <div class="project-info-image">
                        <hersing-image src="/projects/${current_id}.webp"></hersing-image>
                    </div>
                    <div class="project-info-text">
                        ${unsafeHTML(current_html)}
                    </div>
                    <div class="project-info-button" @click=${() => this.showIframe = true}> 
                        <span>
                            Se projekt
                        </span>
                    </div>
                </div>
           </hersing-overlay>
        `
    }

    getHtml = async (current_id: number) => {
        const data = await fetch(`./asserts/rapporter/${current_id}/${current_id}.html`);
        this.current_html = await data.text();
    }

    get iframe() {
        const { current_id } = this;

        const show = current_id != null;
        const url = current_id ? `http://hersing.dk/rapporter/${current_id}/${current_id}semester.pdf` : ''
        return html`
            <hersing-iframe-viewer 
            url="${url}" 
            @hersing_toggle_overlay=${this.toggle_overlay}
            .show=${show}>
            </hersing-iframe-viewer>
        `;
    }

    toggle_overlay(event: CustomEvent<boolean>) {
        if (!event.detail) {
            this.current_id = undefined;
            this.current_html = undefined;
            this.showIframe = false;
            this.setScrollToBody(true);
        }
    }


    get abstractOrIFrame() {
        const { iframe, abstract, showIframe } = this;

        if (showIframe)
            return iframe;

        return abstract;
    }

    override render() {
        const { projects, abstractOrIFrame } = this;
        const degress = projects.filter(p => p.subtitle != null).map(p => this.renderProject(p, true));
        return html`
            <div id="grid">
                ${degress}
                ${projects.map(p => this.renderProject(p, false))}
            </div>
            ${abstractOrIFrame}
        `;
    }

    renderProject(project: Project, showSubtitle: boolean) {
        const { id, subtitle, title, classStr, subClass } = project
        const divId = (showSubtitle ? subClass : classStr) ?? '';
        return html`
            <div title="${title}" class="project ${divId}">
                <a @click=${() => this.openProject(id)}>
                    <hersing-image src="projects/${id}.webp"/>
                    ${this.renderSubTitleHtml(subtitle, showSubtitle)}
                </a>
            </div>
        `;
    }

    openProject(projectId: number) {
        this.current_id = projectId;
        this.getHtml(projectId);
    }

    renderSubTitleHtml(subtitle: string | undefined, showSubtitle: boolean) {
        if (subtitle == null || !showSubtitle)
            return html``;

        return html`
            <div class="subtitle">
                ${subtitle}
            </div>
        `;
    }

    static override get styles() {
        return css`
            #grid{
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                grid-template-rows: repeat(4, 1fr);
                gap: 0px;
                max-width: 1050px;
                margin: 0 auto;
                font-size: 1.5em;
            }

            .div1 { grid-area: 1 / 1 / 2 / 4; }
            .div2 { grid-area: 1 / 4 / 2 / 7; }
            .div3 { grid-area: 2 / 1 / 3 / 3; }
            .div4 { grid-area: 2 / 3 / 3 / 5; }
            .div5 { grid-area: 2 / 5 / 3 / 7; }
            .div6 { grid-area: 3 / 1 / 4 / 3; }
            .div7 { grid-area: 3 / 3 / 4 / 5; }
            .div8 { grid-area: 3 / 5 / 4 / 7; }
            .div9 { grid-area: 4 / 1 / 5 / 3; }
            .div10 { grid-area: 4 / 3 / 5 / 5; }
            .div11 { grid-area: 4 / 5 / 5 / 7; }
            .subtitle{ margin-bottom: 15px; }

            img{
                height: 200px;
            }

            a{
                cursor: pointer
            }

            .project-info-wrapper{
                display: grid;
                grid-template-columns: repeat(9, 1fr);
                grid-template-rows: 1fr 1.1em;
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                height: 100%;
                width: 100%;
                background: white;
                padding: 15px;
                box-sizing: border-box;
                font-size: 1.5em;
            }

            .project-info-image{
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 1;
                grid-row-end: 2;
                text-align: left;
            }

            .project-info-image img{
                height: auto;
                width: 100%;
            }

            .project-info-text{
                grid-column-start: 4;
                grid-column-end: 10;
                grid-row-start: 1;
                grid-row-end: 2;
                text-align: justify;
                overflow: auto;
            } 
            .project-info-button{
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 2;
                grid-row-end: 3;
            }

            .project-info-button span{
                cursor: pointer;
                font-weight: bold;
            }
        `;
    }
}