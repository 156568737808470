
import { html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js'

import { facebook, rss, linkedin, instagram } from '../../svgs';
import { Section } from './section.base';

@customElement('hersing-section-contact')
export class HersingSectionContact extends Section {

    @state()
    current_link: string | undefined;

    social = [
        { link: 'https://www.facebook.com/hersing', image: facebook },
        { link: 'http://hersing.dk/rss.php', image: rss },
        { link: 'http://www.linkedin.com/in/hersing', image: linkedin },
        { link: 'https://www.instagram.com/danielhersing', image: instagram }
    ];


    *getLinks() {
        const { social } = this;

        for (const { image, link } of social) {
            yield html`
                <li>
                    <a href="${link}" target="_blank">
                        ${image}
                    </a>
                </li>
            `
        }
    }

    override render() {
        return html`
            <div id="contact_info" class="website-div">
                <ul class="social_icons">
                    ${this.getLinks()}
                </ul>
            </div>
        `;
    }

    static override get styles() {
        return css`
            ul{
                list-style: none;
                padding: 0;
            }
            
            li{
                display: inline-block;
                margin: 0 15px;
            }

            svg{
                height: 50px;
                cursor: pointer;
                fill: var(--font-color);
            }
        `;

    }
}