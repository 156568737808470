
import { css, html, HTMLTemplateResult } from 'lit';
import { customElement, state } from 'lit/decorators.js'

import '@ptkdev/webcomponent-instagram-widget';
import { Section } from './section.base';

@customElement('hersing-section-instagram')
export class HersingSectionInstagram extends Section {

    instagram_username = 'danielhersing';
    instagram_amount = 25;
    get jsonurl(){
        const {instagram_username} = this;
        return `https://profilepageimages.usecue.com/images/${instagram_username}/images.json`;
    }

    @state()
    images: HTMLTemplateResult[] = [];

    handleGottenData = (results: any)  => {
        this.images = [];
        for(const result of results){
            var image_small = result.image_small;
            var caption = result.caption ?? '';
            var permalink = result.permalink.replace(/\/$/, '');
            var media_type = result.media_type.toLowerCase();
            const listELement = html`
                <li style="background-image: url(${image_small})" class="${media_type}">
                    <a href="${permalink}" target="_blank" title="${caption}">
                        <img src="${image_small}" alt="${caption}"/>
                    </a>
                </li>`;

            this.images.push(listELement)
        }
    }

    load(){
        const { jsonurl, handleGottenData}= this;
        const request = new XMLHttpRequest();
        request.open('GET', jsonurl, true);
        request.onload = function(){
            if (this.status >= 200 && this.status < 400) {
                // Success!
                var data = JSON.parse(this.response);
                handleGottenData(data);
            } 
        }
        request.onerror = function() {
            console.error('Instagram siger øv')
        };
        request.send();
    }

    override connectedCallback(){
        super.connectedCallback();
        this.load();
    }

    override render() {
        const {images} = this;
        return html`
        <ul id="instafeed">
            ${images}
        </ul>
        `;
    }

    static override get styles() {
        return css`
        #instafeed {
            max-width:1050px;
            margin: auto; 
            padding: 0;
            display: block;
        }
        #instafeed li {
            margin: 0;
            padding: 0;
            list-style: none;
            background: url() center center / cover no-repeat;
            position: relative;
            height: 200px;
            width: 200px;
            display: inline-block;
        }
        #instafeed li a {
            display: block;
            padding-bottom: 100%;
        }
        #instafeed li.video a:before {
            content: "";
            background: url(https://profilepageimages.usecue.com/img/play.svg) center center / 45% auto no-repeat;
            width: 32px;
            height: 32px;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }
        #instafeed li.carousel_album a:before {
            content: "";
            background: url(https://profilepageimages.usecue.com/img/carousel.svg) center center / 50% auto no-repeat;
            width: 32px;
            height: 32px;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }
        #instafeed li:hover a::after {
            content: "";
            position: absolute;
            left: 0; 
            top: 0;
            width: 100%;
            height: 100%;
            background: url(https://profilepageimages.usecue.com/img/interact.svg) center center / auto 19px no-repeat;
            z-index: 1;
        }
        #instafeed li a img {
            display: none;
        }

        /* show only 18 images on mobile */
        @media only screen and (max-width: 600px) {
            #instafeed li:nth-child(n+18) {
                display: none;
            }
        }   
        `;
    }
}