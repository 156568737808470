import { html, css } from 'lit';
import { customElement } from 'lit/decorators.js'

import { Section } from './section.base';

@customElement('hersing-section-my-skills')
export class HersingSectionMySkills extends Section {

    skills = [
        { title: 'Design', image: 'skills/design.png',
        description: `I have been working with digital design, mainly photoshop editing, but I do have more skills than that. 
        My education has given me the skills to create and design events, performances, concerts and art shows. 
        I know the Aesthetics, Practical and Analytics from an Experience Economy point-of-view.`},

        { title: 'Developing', image: 'skills/webdesign.png',
        description: `I have been working as a full-stacker webdeveloper for the last 6 years and therefore I know the most important things for designing the perfect website. 
        HTML, TypeScript, C#, WebComponents, KnockOutJs, PHP, Ingres, MySQL, JavaScript, jQurey, DOM, Human Computer Interaction (HCI), 
        Usability and Usability Evaluation are just some of the skills I have learned over the years. 
        `},

        { title: 'Photography and Video', image: 'skills/photography.png',
        description: `I don't own the most expensive equipment, but I still create great photos with my camera. 
        Apart from that I have learned a lot from editing pictures in Lightroom and sometimes Photoshop. 
        I have also worked with filming, making effects in After Effects and editing videos in Final Cut Pro, Lightroom and iMovie`}
    ];

    *getSkills(){
        const { skills } = this;

        for(const { title, image, description } of skills){
            yield html`
                <div class="service">
                    <div class="icon_container">
                        <div class="icon">
                            <hersing-image src="${image}" class="skills-image" alt="design_white"></hersing-image>
                        </div>
                    </div>
                    <h3>${title}</h3>
                    <p>${description}</p>
                </div>
            `;
        }
    }

    override render() {
        return html`
            	<p>Here can read about my skills in the fields that i am educated and able to work in.</p>
                <div id="service_blocks">
					${this.getSkills()}
                </div>
        `;
    }

    static override get styles() {
        return css`
        #service_blocks{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            margin: 0 auto;
            max-width: 1050px;
        }

        .icon_container{
            display: inline-block;
            border: 1px solid var(--font-color);
            border-radius: 50%;
            height: 258px;
            margin-bottom: 35px;
            width: 258px;
        }

        .icon{
            border-radius: 50%;
            height: 90px;
            margin: 4px;
            padding-bottom: 76px;
            padding-top: 84px;
            text-align: center;
            width: 250px;
            background-color: var(--font-color);
        }

        p{
            text-align: center;
            font-size: 1.5em;
        }

        hersing-image{
            height: 90px;
        }

        a{
            color: inherit;
            display: block;
            text-decoration: none;
        }
        `;
    }
}